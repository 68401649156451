<div class="container-fluid h-100 pb-3 full-height">
  @if (loading) {
    <div class="row pt-3">
      <div class="col text-center">
        <i class="fa fa-spinner spinning fa-2x"></i>
      </div>
    </div>
  }
  @if (!loading) {
    @if (data.length == 0) {
      <div class="row pt-3">
        <div class="col text-center">
          {{ 'global.Aucun résultat trouvé' | translate }}
        </div>
      </div>
    }
    <div class="row">
      @for (kpi of data; track kpi) {
        <div class="col-12 col-md-6">
          <hiji-table-kpi [objective]="kpi"></hiji-table-kpi>
        </div>
      }
    </div>
  }
</div>
