import { Component, OnChanges, OnInit, inject, input } from '@angular/core';
import { Filter, FilterForm, FilterOrder, GenericFilter, L, OrderBy } from '@ic2/ic2-lib';
import { EMAObjectiveFormula, EMAObjectiveLiteDTO, EMAObjectiveType, EMATeamMemberObjectiveResultDTO, TeamEMAORService } from 'common';

@Component({
    selector: 'hiji-table-kpi',
    templateUrl: './table-kpi.component.html',
    styleUrls: ['./table-kpi.component.scss'],
    standalone: false
})
export class TableKpiComponent implements OnInit, OnChanges {
  private teamEMAORService = inject(TeamEMAORService);

  OrderBy: typeof OrderBy = OrderBy;
  order: FilterOrder[] = [new FilterOrder('r/o', OrderBy.DESC)];
  page: number = 1;
  nbPerPage: number = 25;
  count = 0;
  loading: boolean = true;

  static filtersCache = {};
  filtersDefault = {};
  form: FilterForm = new FilterForm(this.reload.bind(this), TableKpiComponent.filtersCache, {}, {});

  data: EMATeamMemberObjectiveResultDTO[] = [];
  readonly objective = input<EMAObjectiveLiteDTO>(undefined);

  EMAObjectiveType = EMAObjectiveType;

  ngOnInit(): void {
    this.reload();
  }

  ngOnChanges(): void {}

  reload() {
    const genericFilter = new GenericFilter();
    genericFilter.filters = this.form.toFilters();
    genericFilter.orderFilters = this.order;
    genericFilter.page = this.page;
    genericFilter.nbPerPage = this.nbPerPage;
    this.loading = true;
    this.teamEMAORService.getKpi(this.objective().idEMAObjective, genericFilter).subscribe(
      (data) => {
        this.data = data.data;
        this.count = data.count;
        this.loading = false;
      },
      (err) => {
        this.loading = false;
        L.e(err);
      }
    );
  }

  orderChange(order) {
    this.reload();
  }

  filterChanged(filters: Filter[]) {
    this.reload();
  }

  compareFn(columnName: string) {
    return (a: EMATeamMemberObjectiveResultDTO, b: EMATeamMemberObjectiveResultDTO) => {
      return a[columnName].join(',').localeCompare(b[columnName].join(','));
    };
  }

  getStyle(emaInfo: EMATeamMemberObjectiveResultDTO): { [key: string]: string } {
    if (!emaInfo || emaInfo.rank === 0) {
      return null;
    }

    let colors = ['', '#48bbc2', '#56af94', '#2a4897'];
    return { 'background-color': colors[emaInfo.rank], color: 'white' };
  }

  getResult(r: EMATeamMemberObjectiveResultDTO): number {
    const objective = this.objective();
    if (objective.showAsPercentageInGraph && r.objective !== null && r.objective !== 0) {
      if (objective.formula === EMAObjectiveFormula.RO) return (r.result / r.objective) * 100;
      else if (objective.formula === EMAObjectiveFormula.OR) return (r.objective / r.result) * 100;
      else throw new Error('Invalid formula');
    }

    return r.result;
  }
  getTooltip(r: EMATeamMemberObjectiveResultDTO): string {
    const objective = this.objective();
    if (objective.showAsPercentageInGraph) {
      if (objective.formula === EMAObjectiveFormula.RO) return r.result + ' / ' + r.objective;
      else if (objective.formula === EMAObjectiveFormula.OR) return r.objective + ' / ' + r.result;
      else throw new Error('Invalid formula');
    }

    return null;
  }
}
